import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { APIClient } from "../../helpers/apiClient";
import { getFirebaseBackend } from "../../helpers/firebase";

import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  FORGET_PASSWORD,
} from "./constants";

import {
  setErrorMessage,
  loginUserSuccess,
  registerUserSuccess,
  forgetPasswordSuccess,
  apiError,
} from "./actions";

//Initilize firebase
const fireBaseBackend = getFirebaseBackend();

/**
 * Sets the session
 * @param {*} user
 */

const create = new APIClient().create;

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        username,
        password
      );
      yield put(loginUserSuccess(response));
    } else {
      const response = yield call(create, "/users/login", {
        phone: username,
        password,
      });
      if (response.status === "success") {
        localStorage.setItem("authUser", JSON.stringify(response));
        yield put(loginUserSuccess(response));
      } else {
        localStorage.removeItem("authUser");
        yield put(setErrorMessage("號碼或密碼錯誤"));
        /*
        reject({
          message:
            "號碼或密碼錯誤",
        });
        */
      }
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError("連線失敗"));
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      yield call(fireBaseBackend.logout);
    }
    yield call(() => {
      history.push("/login");
    });
    yield call(() => {
      window.location.reload();
    });
  } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { user } }) {
  try {
    const email = user.email;
    const password = user.password;
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.registerUser,
        email,
        password
      );
      yield put(registerUserSuccess(response));
    } else {
      const response = yield call(create, "/register", user);
      yield put(registerUserSuccess(response));
    }
  } catch (error) {
    yield put(apiError("連線失敗"));
  }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { phone } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.forgetPassword, phone);
      if (response) {
        yield put(forgetPasswordSuccess("已寄送密碼至您的手機"));
      }
    } else {
      const response = yield call(create, "/users/forgetPassword", { phone });
      yield put(forgetPasswordSuccess(response));
    }
  } catch (error) {
    yield put(apiError("連線失敗"));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgetPassword),
  ]);
}

export default authSaga;
