import {
  SET_USER_INFO,
  SET_ACTIVE_TAB,
  OPEN_USER_PROFILE_SIDEBAR,
  CLOSE_USER_PROFILE_SIDEBAR,
  SET_CONVERSATION_NAME_IN_OPEN_CHAT,
  SET_NOTIFICATION_NB,
  SET_CHAT_NB,
} from "./constants";

export const setUserInfo = (userInfo) => ({
  type: SET_USER_INFO,
  payload: userInfo,
});

export const setActiveTab = (tabId) => ({
  type: SET_ACTIVE_TAB,
  payload: tabId,
});

export const openUserSidebar = () => ({
  type: OPEN_USER_PROFILE_SIDEBAR,
});

export const closeUserSidebar = () => ({
  type: CLOSE_USER_PROFILE_SIDEBAR,
});

export const setconversationNameInOpenChat = (conversationName) => ({
  type: SET_CONVERSATION_NAME_IN_OPEN_CHAT,
  payload: conversationName,
});

export const setNotificationNB = (nb) => ({
  type: SET_NOTIFICATION_NB,
  payload: nb,
});

export const setChatNB = (nb) => ({
  type: SET_CHAT_NB,
  payload: nb,
});
