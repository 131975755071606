// @flow
import {
  SET_CHAT_NB,
  SET_USER_INFO,
  SET_ACTIVE_TAB,
  OPEN_USER_PROFILE_SIDEBAR,
  CLOSE_USER_PROFILE_SIDEBAR,
  SET_CONVERSATION_NAME_IN_OPEN_CHAT,
  SET_NOTIFICATION_NB,
} from "./constants";

const INIT_STATE = {
  userInfo: {
    name: "",
    description: "",
    job: "",
    image: "",
    userId: "",
  },
  activeTab: "profile",
  userSidebar: false,
  conversationName: "Doris Brown",
  notificationNB: 0,
  chatNB: 0,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case OPEN_USER_PROFILE_SIDEBAR:
      return {
        ...state,
        userSidebar: true,
      };

    case CLOSE_USER_PROFILE_SIDEBAR:
      return {
        ...state,
        userSidebar: false,
      };

    case SET_CONVERSATION_NAME_IN_OPEN_CHAT:
      return {
        ...state,
        conversationName: action.payload,
      };

    case SET_NOTIFICATION_NB:
      return {
        ...state,
        notificationNB: action.payload,
      };

    case SET_CHAT_NB:
      return {
        ...state,
        chatNB: action.payload,
      };
    default:
      return state;
  }
};

export default Layout;
